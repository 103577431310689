import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import './App.css';

const axios = require('axios');
const resumeUrl = 'http://71.245.88.22:5002/uploader/resume-parser';
const uploadUrl = 'https://6p5yim1xyd.execute-api.us-east-1.amazonaws.com/dev/upload-files';
// const fileUrl = 'https://upload-resumes-classifier-labeller.s3.amazonaws.com/';
const validFileExtensions = ['.doc', '.docx', '.pdf', '.ppt', '.pptx', '.jpeg', '.png', '.jpg'];
const validMimeType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'image/jpeg', 'image/png', 'image/pjpeg'];
class InputForm extends Component {

    state = {
    	file: '',
        msg: '',
        data: '',
        outputData: '',
        previousFilename: '',
        displayFile: false,
        completeFileUrl: '',
        loading: true,
        disabled: true,
        disabledFile: false,
        isAlert: false,
        alertMessage: '',
        outputSkills: [],
        imgExtension: false,
        lengthIntroduction: 0,
        lengthEducation: 0,
        lengthAcademicProject: 0,
        lengthWorkExp: 0,
        lengthWorkProjects: 0,
        lengthKnowledge: 0,
        lengthAchievement: 0,
        lengthExtraCurricular: 0,
        lengthSummary: 0,
        lengthAddress: 0,
        lengthPhone: 0,
        lengthContact: 0,
    };

    onFileChange = (event) => {
      this.setState({disabled: true});
      if (!event.target.files[0])
      {
        this.setState({displayFile: true});
        return
      }
      this.setState({
        file: event.target.files[0],
        disabled: false,
        displayFile: false
      });
      this.setState({previousFilename: event.target.files[0].name})
		};

	uploadFileData = async (event) => {
		event.preventDefault();
		this.setState({
      msg: '',
      outputData: '',
      completeFileUrl: '',
      imgExtension: false
		});

		let fileData= new FormData();
		fileData.append('file', this.state.file);

		this.setState({msg: "File received"});

		this.setState({loading: false, disabled: true, disabledFile: true});

		try {

		  let extension = this.state.file.type;
		  let flag = 0;
		  for (let i=0; i<validMimeType.length; i++)
          {
            if (extension === validMimeType[i])
            {
              flag = 1;
              if(i>=4 && i<=6)
              {
                this.setState({imgExtension: true})
              }
              break;
            }
          }

		  if(flag===0) {
        console.log("File Type Not Valid");
        this.setState({isAlert:true, alertMessage: 'File Type Not Valid',loading: true, disabledFile: false});
        return false
        } else
        {
            const uploadFileName = await axios.post(`${uploadUrl}`,
                fileData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
            );

            // this.setState({data: { 'resume_link': 'https://www.dropbox.com/s/gy6v8oumrm6yz4y/1.pdf?dl=1' }});
            this.setState({completeFileUrl: `${uploadFileName['data']}`});
            this.setState({data: { 'resume_link': this.state.completeFileUrl }});

            const resp = await axios.post(`${resumeUrl}`,
                this.state.data, {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
            );
            const uploadOutput = resp.data;

            this.setState({outputData: uploadOutput});

            if (uploadOutput.introduction.phone[0].internationalPhoneNumberFormat.length || uploadOutput.introduction.phone[0].extractedPhoneNumber.length || uploadOutput.introduction.phone[0].code.length || uploadOutput.introduction.phone[0].countryIsoCode.length || uploadOutput.introduction.phone[0].extractedNumericCountryCode.length)
            {
                this.setState({lengthPhone: 1});
            }
            if (uploadOutput.introduction.address[0].fullAdd.length || uploadOutput.introduction.address[0].correspondenceAddress.length || uploadOutput.introduction.address[0].permanentAddress.length)
            {
                this.setState({lengthAddress: 1});
            }

            if (uploadOutput.introduction.contact.instagram[0] || uploadOutput.introduction.contact.facebook[0] || uploadOutput.introduction.contact.medium[0] || uploadOutput.introduction.contact.github[0] || uploadOutput.introduction.contact.twitter[0] || uploadOutput.introduction.contact.linkedin[0] || uploadOutput.introduction.contact.stackoverflow[0])
            {
                this.setState({lengthContact: 1});
            }

            if (uploadOutput.introduction.name || uploadOutput.introduction.email[0] || uploadOutput.introduction.gender || uploadOutput.introduction.language[0] ||  uploadOutput.introduction.bloodGroup || uploadOutput.introduction.dateOfBirth || uploadOutput.introduction.maritalStatus || uploadOutput.introduction.nationality || this.state.lengthContact || this.state.lengthAddress || this.state.lengthPhone || uploadOutput.introduction.extra[0])
            {
                this.setState({lengthIntroduction: 1});
            }

            if (uploadOutput.education.education[0].name || uploadOutput.education.education[0].year || uploadOutput.education.education[0].marks || uploadOutput.education.education[0].course || uploadOutput.education.education[0].board || uploadOutput.education.education[0].location || uploadOutput.education.education[0].extra)
            {
                this.setState({lengthEducation: 1});
            }

            if (uploadOutput.acadProject.academicProjects[0].name || uploadOutput.acadProject.academicProjects[0].desc || uploadOutput.acadProject.academicProjects[0].duration || uploadOutput.acadProject.academicProjects[0].skills[0] || uploadOutput.acadProject.academicProjects[0].extra)
            {
                this.setState({lengthAcademicProject: 1});
            }

            if (uploadOutput.workExperience.work[0].name || uploadOutput.workExperience.work[0].role || uploadOutput.workExperience.work[0].location || uploadOutput.workExperience.work[0].client || uploadOutput.workExperience.work[0].desc || uploadOutput.workExperience.work[0].duration || uploadOutput.workExperience.work[0].skills[0] || uploadOutput.workExperience.work[0].extra)
            {
                this.setState({lengthWorkExp: 1});
            }

            if (uploadOutput.workProject.workProjects[0].projectName || uploadOutput.workProject.workProjects[0].companyName || uploadOutput.workProject.workProjects[0].client || uploadOutput.workProject.workProjects[0].duration || uploadOutput.workProject.workProjects[0].role || uploadOutput.workProject.workProjects[0].skills[0] || uploadOutput.workProject.workProjects[0].desc || uploadOutput.workProject.workProjects[0].extra)
            {
                this.setState({lengthWorkProjects: 1});
            }

            if (uploadOutput.knowledge.skills[0].skillType || uploadOutput.knowledge.skills[0].skills[0])
            {
                this.setState({lengthKnowledge: 1});
            }

            if (uploadOutput.achievement.achievements[0].achieveType || uploadOutput.achievement.achievements[0].achievements || uploadOutput.achievement.achievements[0].duration)
            {
                this.setState({lengthAchievement: 1});
            }

            if(uploadOutput.extraCurricular.extracurricular[0].type || uploadOutput.extraCurricular.extracurricular[0].desc)
            {
                this.setState({lengthExtraCurricular: 1});
            }


            // let data = output["skill"];
            // let len = data.length;
            // 	  let n = 3;
            // 	  let outputArray = [];
            // 	  if(len === 0)
            // {
            //   this.setState({outputSkills: outputArray})
            // }
            // 	  else if (len<n)
            // {
            //   outputArray = data;
            //   this.setState({outputSkills: outputArray})
            // }
            // 	  else {
            // 	    for (let i=0; i<len; i+=n)
            //   {
            //     outputArray.push(data.slice(i, i+n));
            //   }
            //   this.setState({outputSkills: outputArray})
            // }
        this.setState({loading: true, disabledFile: false});
      }
		}
		catch (e) {
		    let errorMessage = "";
		    try{
		        switch (e.response.status) {
                    case 400:
                        errorMessage = "Not a Valid Resume File! Upload a valid resume file";
                        break;
                    case 404:
                        errorMessage = "Not a Valid Link! Upload a valid resume file";
                        break;
                    case 500:
                        errorMessage = "Internal Server Error!";
                        break;
                    default:
                        errorMessage = "Unhandled";
                        break;
                }
            }
		    catch (e)
            {
                // Network Error doesn't return a response code
                errorMessage = "Service Not Available"
            }

		  this.setState({isAlert:true, alertMessage: String(errorMessage),loading: true, disabledFile: false});
          console.error(e.response);
          console.error(errorMessage);
		}
	};

	handleClose = () => {
      this.setState({isAlert: false, alertMessage: ''});
      this.refs.file.value = '';
    };

    render() {
      const styles = this.state.isAlert ? {display: 'block'} : {display: 'none'};
      return (
        <div className={"container-fluid"}>
          <div className={"row rowBody"}>
            <div className={'col-sm-1'}>
            </div>
            <div className={'col-sm-10'}>
              <h1 className={'text-center'}>Resume Upload!</h1>
              <h3 className={'text-center'}>Upload a File</h3>
              <form className="form-inline text-center formMargin">
                <div className="form-group">
                  <input type={"file"} className={"form-control"} onChange={this.onFileChange} accept={validFileExtensions} disabled={this.state.disabledFile} ref={"file"}></input>
                </div>
                <button className={'btn btn-primary fileHandleButton'} disabled={this.state.disabled} onClick={this.uploadFileData}>Upload</button>
              </form>
              <br className={"brMargin"}/>
              {
                this.state.loading? '' :
                  <center>
                    <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                </center>
              }
              {
                this.state.displayFile ?
                  <div>
                    <h4 className={'text-center'}> Showing results for file: {this.state.previousFilename} </h4>
                  </div>
                  : null
              }
              {
                this.state.outputData ?
                  <div className={"row"}>
                    <div className={'col-sm-6'}>
                      {
                        this.state.imgExtension ?
                          <img
                            src={this.state.completeFileUrl}
                            alt='resumeImage'
                            width={"100%"}
                            height={"800"}/>
                          :
                           <iframe title={"Display Resume"}
                              className={"filetype"}
                              frameBorder="0"
                              src={`https://docs.google.com/gview?url=${this.state.completeFileUrl}&embedded=true`}
                              width={"100%"}
                              height={"800"}
                           >
                           </iframe>
                      }

                    </div>
                    <div className={'col-sm-6'}>
                     {this.state.outputData.introduction && this.state.lengthIntroduction ?
                         <>
                         <h4 className={'pMargin'}>Introduction: </h4>
                         <table className={'table table-bordered table-striped table-hover tableBordered'}>
                            <tbody>
                              {this.state.outputData.introduction.name ?
                                  <tr>
                                      <th>Name</th>
                                      <td>{this.state.outputData.introduction.name}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.email[0] ?
                                  <tr>
                                      <th>Email</th>
                                      <td>
                                        {
                                            this.state.outputData.introduction.email.map(function(data, index){
                                                return (
                                                    <li key={index}> {data} </li>
                                                )
                                            })
                                        }
                                      </td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.phone && this.state.lengthPhone ?
                                  <>
                                      {this.state.outputData.introduction.phone.map(function(data, index){
                                         return (
                                             <tr>
                                                 <th>Phone Number </th>
                                                 <td>
                                                     <table className={'table table-bordered table-striped table-hover'} key={index}>
                                                         <tbody>
                                                             {data.extractedNumericCountryCode ?
                                                                 <tr>
                                                                     <th style={{width: "40%"}}>Country Code</th>
                                                                     <td>{data.extractedNumericCountryCode}</td>
                                                                 </tr>
                                                                 : null
                                                             }
                                                             {data.extractedPhoneNumber ?
                                                                <tr>
                                                                    <th style={{width: "40%"}}>Phone Number</th>
                                                                    <td >{data.extractedPhoneNumber}</td>
                                                                </tr>
                                                                : null
                                                            }
                                                            {data.internationalPhoneNumberFormat ?
                                                                <tr>
                                                                    <th style={{width: "40%"}}>International Phone Number</th>
                                                                    <td>{data.internationalPhoneNumberFormat}</td>
                                                                </tr>
                                                                : null
                                                            }
                                                            {data.countryIsoCode ?
                                                                <tr>
                                                                    <th style={{width: "40%"}}>Country ISO Code</th>
                                                                    <td>{data.countryIsoCode}</td>
                                                                </tr>
                                                                :  null
                                                            }
                                                            {data.code ?
                                                                <tr>
                                                                    <th style={{width: "40%"}}>Code</th>
                                                                    <td>{data.code}</td>
                                                                </tr>
                                                                : null
                                                            }
                                                         </tbody>
                                                     </table>
                                                 </td>
                                             </tr>
                                         )
                                      })}
                                  </>
                                  : null
                              }
                              {this.state.outputData.introduction.dateOfBirth ?
                                  <tr>
                                      <th>Date of Birth</th>
                                      <td>{this.state.outputData.introduction.dateOfBirth}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.address && this.state.lengthAddress ?
                                  <>
                                      {this.state.outputData.introduction.address.map(function(data, index){
                                         return (
                                             <tr>
                                                 <th>Address </th>
                                                 <td>
                                                     <table className={'table table-bordered table-striped table-hover'} key={index}>
                                                         <tbody>
                                                             {data.fullAdd ?
                                                                 <tr>
                                                                    <th style={{width: "15%"}}>Full Address</th>
                                                                    <td>{data.fullAdd}</td>
                                                                 </tr>
                                                                 : null
                                                             }
                                                             {data.correspondenceAddress ?
                                                                <tr>
                                                                    <th style={{width: "15%"}}>Correspondence Address</th>
                                                                    <td>{data.correspondenceAddress}</td>
                                                                </tr>
                                                                : null
                                                            }
                                                            {data.permanentAddress ?
                                                                <tr>
                                                                    <th style={{width: "15%"}}>Permanent Address</th>
                                                                    <td>{data.permanentAddress}</td>
                                                                </tr>
                                                                : null
                                                            }
                                                        </tbody>
                                                     </table>
                                                </td>
                                            </tr>
                                         )
                                      })}
                                  </>
                              : null}
                              {this.state.outputData.introduction.language[0] ?
                                  <tr>
                                      <th>Language</th>
                                      <td>{this.state.outputData.introduction.language.join(", ")}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.gender ?
                                  <tr>
                                      <th>Gender</th>
                                      <td>{this.state.outputData.introduction.gender}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.maritalStatus ?
                                  <tr>
                                      <th>Marital Status</th>
                                      <td>{this.state.outputData.introduction.maritalStatus}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.nationality ?
                                  <tr>
                                      <th>Nationality</th>
                                      <td>{this.state.outputData.introduction.nationality}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.bloodGroup ?
                                  <tr>
                                      <th>Blood Group</th>
                                      <td>{this.state.outputData.introduction.bloodGroup}</td>
                                  </tr>
                                  : null
                              }
                              {this.state.outputData.introduction.contact && this.state.lengthContact ?
                                  <tr>
                                    <th>Contact </th>
                                    <td>
                                      <table className={'table table-bordered table-striped table-hover'}>
                                        <tbody>
                                            {this.state.outputData.introduction.contact.facebook.length!==0 ?
                                              <tr>
                                                  <th>Facebook</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.facebook.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                              : null
                                            }
                                          {this.state.outputData.introduction.contact.github.length!==0 ?
                                              <tr>
                                                  <th>GitHub</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.github.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }
                                          {this.state.outputData.introduction.contact.instagram.length!==0 ?
                                              <tr>
                                                  <th>Instagram</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.instagram.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }
                                          {this.state.outputData.introduction.contact.medium.length!==0 ?
                                              <tr>
                                                  <th>Medium</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.medium.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }
                                          {this.state.outputData.introduction.contact.twitter.length!==0 ?
                                              <tr>
                                                  <th>Twitter</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.twitter.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }

                                          {this.state.outputData.introduction.contact.linkedin.length!==0 ?
                                              <tr>
                                                  <th>LinkedIn</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.linkedin.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }

                                          {this.state.outputData.introduction.contact.stackoverflow.length!==0 ?
                                              <tr>
                                                  <th>Stackoverflow</th>
                                                  <td>
                                                      {this.state.outputData.introduction.contact.stackoverflow.map(function(data, index){
                                                        return (
                                                            <li key={index}> {data} </li>
                                                        )
                                                      })}
                                                  </td>
                                              </tr>
                                          : null
                                          }
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  : null
                              }

                              {this.state.outputData.introduction.extra.length!==0 ?
                                  <tr>
                                      <th>Extra</th>
                                      <td>{this.state.outputData.introduction.extra}</td>
                                  </tr>
                                  : null
                              }
                            </tbody>
                         </table>
                         </>
                         : null
                        }

                      {this.state.outputData.education && this.state.lengthEducation ?
                          <>
                          <h4 className={'pMargin'}>Education: </h4>
                          <div>
                            {this.state.outputData.education.education.map(function(data, index){
                                return (
                                  <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                    <tbody>
                                      {data.name ?
                                          <tr>
                                              <th style={{width: "25%"}}>Insitution Name</th>
                                              <td>{data.name}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.course ?
                                          <tr>
                                              <th style={{width: "25%"}}>Course</th>
                                              <td>{data.course}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.board ?
                                          <tr>
                                              <th style={{width: "25%"}}>Board</th>
                                              <td>{data.board}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.location ?
                                          <tr>
                                              <th style={{width: "25%"}}>Location</th>
                                              <td>{data.location}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.year ?
                                          <tr>
                                              <th style={{width: "25%"}}>Year</th>
                                              <td>{data.year}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.marks ?
                                          <tr>
                                              <th style={{width: "25%"}}>Marks</th>
                                              <td>{data.marks}</td>
                                          </tr>
                                          : null
                                      }
                                      {data.extra ?
                                          <tr>
                                              <th style={{width: "25%"}}>Extra</th>
                                              <td>{data.extra}</td>
                                          </tr>
                                          : null
                                      }
                                    </tbody>
                                  </table>
                                )
                            })}
                          </div>
                          </>
                          : null
                      }

                      {
                        this.state.outputData.acadProject.academicProjects && this.state.lengthAcademicProject ?
                            <>
                            <h4 className={'pMargin'}> Academic Projects: </h4>
                            <div>
                              {this.state.outputData.acadProject.academicProjects.map(function(data, index){
                                  let skills = ""
                                  data.skills.forEach(function (dataSkill, index) {
                                    skills+=dataSkill.charAt(0).toUpperCase() + dataSkill.slice(1)
                                      if(index !== data.skills.length-1){
                                          skills+= ", "
                                      }
                                  })
                                  return (
                                   <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                     <tbody>
                                         {data.name ?
                                             <tr>
                                              <th style={{width: "25%"}}>Project Name</th>
                                              <td>{data.name}</td>
                                             </tr>
                                             : null
                                         }
                                         {data.duration ?
                                             <tr>
                                              <th style={{width: "25%"}}>Duration</th>
                                              <td>{data.duration}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.desc ?
                                             <tr>
                                              <th style={{width: "25%"}}>Description</th>
                                              <td>{data.desc}</td>
                                             </tr>
                                             : null

                                         }
                                         {skills ?
                                             <tr>
                                              <th style={{width: "25%"}}>Skills</th>
                                              <td>{skills}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.extra ?
                                             <tr>
                                              <th style={{width: "25%"}}>Extra</th>
                                              <td>{data.extra}</td>
                                             </tr>
                                             : null

                                         }

                                     </tbody>
                                   </table>
                                   )
                                 })}
                            </div>
                            </>
                          : null

                      }

                      {
                        this.state.outputData.workExperience && this.state.lengthWorkExp ?
                          <>
                          <h4 className={'pMargin'}>Work Experience: </h4>
                            <div>
                            {this.state.outputData.workExperience.work.map(function(data, index){
                              let skills = ""
                              data.skills.forEach(function (dataSkill, index) {
                                skills+=dataSkill.charAt(0).toUpperCase() + dataSkill.slice(1)
                                  if(index !== data.skills.length-1){
                                      skills+= ", "
                                  }
                              })
                              return (
                                <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                  <tbody>
                                      {data.name ?
                                          <tr>
                                            <th style={{width: "25%"}}>Company Name</th>
                                            <td>{data.name}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.role ?
                                          <tr>
                                            <th style={{width: "25%"}}>Role</th>
                                            <td>{data.role}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.location ?
                                          <tr>
                                            <th style={{width: "25%"}}>Location</th>
                                            <td>{data.location}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.duration ?
                                          <tr>
                                            <th style={{width: "25%"}}>Duration</th>
                                            <td>{data.duration}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.client ?
                                          <tr>
                                            <th style={{width: "25%"}}>Client Name</th>
                                            <td>{data.client}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.desc ?
                                          <tr>
                                            <th style={{width: "25%"}}>Description</th>
                                            <td>{data.desc}</td>
                                          </tr>
                                          : null
                                      }

                                      {skills ?
                                          <tr>
                                            <th style={{width: "25%"}}>Skills</th>
                                            <td>{skills}</td>
                                          </tr>
                                          : null
                                      }

                                      {data.extra ?
                                          <tr>
                                            <th style={{width: "25%"}}>Extra</th>
                                            <td>{data.extra}</td>
                                          </tr>
                                          : null
                                      }

                                  </tbody>
                                </table>
                              )
                            })}
                          </div>
                          </>
                        : null
                      }

                      {
                        this.state.outputData.workProject && this.state.lengthWorkProjects ?
                            <>
                            <h4 className={'pMargin'}> Work Projects: </h4>
                            <div>
                              {this.state.outputData.workProject.workProjects.map(function(data, index){
                                  let skills = ""
                                  data.skills.forEach(function (dataSkill, index) {
                                    skills+=dataSkill.charAt(0).toUpperCase() + dataSkill.slice(1)
                                      if(index !== data.skills.length-1){
                                          skills+= ", "
                                      }
                                  })
                                  return (
                                   <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                     <tbody>
                                         {data.projectName ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Project Name</th>
                                                  <td>{data.projectName}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.companyName ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Company Name</th>
                                                  <td>{data.companyName}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.duration ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Duration</th>
                                                  <td>{data.duration}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.role ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Role</th>
                                                  <td>{data.role}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.client ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Client</th>
                                                  <td>{data.client}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.desc ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Description</th>
                                                  <td>{data.desc}</td>
                                             </tr>
                                             : null

                                         }
                                         {skills ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Skills</th>
                                                  <td>{skills}</td>
                                             </tr>
                                             : null

                                         }
                                         {data.extra ?
                                             <tr>
                                                  <th style={{width: "25%"}}>Extra</th>
                                                  <td>{data.extra}</td>
                                             </tr>
                                             : null

                                         }

                                     </tbody>
                                   </table>
                                   )
                              })}
                            </div>
                            </>
                          : null
                      }

                      {
                        this.state.outputData.knowledge && this.state.lengthKnowledge ?
                          <>
                          <h4 className={'pMargin'}> Knowledge: </h4>
                          <div>
                            {this.state.outputData.knowledge.skills.map(function(data, index){
                              let skills = ""
                              data.skills.forEach(function (dataSkill, index) {
                                skills+=dataSkill.charAt(0).toUpperCase() + dataSkill.slice(1)
                                  if(index !== data.skills.length-1){
                                      skills+= ", "
                                  }
                              })
                               return (
                                 <table className={'table table-bordered table-striped table-hover table-bordered tableBordered'} key={index}>
                                   <tbody>
                                       {data.skillType ?
                                           <tr>
                                                <th style={{width: "25%"}}>Skill Type</th>
                                                <td>{data.skillType}</td>
                                           </tr>
                                           : null

                                       }
                                       {skills ?
                                           <tr>
                                                <th style={{width: "25%"}}>Skills</th>
                                                <td>{skills}</td>
                                           </tr>
                                           : null

                                       }
                                   </tbody>
                                  </table>
                               )
                             })}
                          </div>
                          </>
                          : null
                      }

                      {
                        this.state.outputData.achievement && this.state.lengthAchievement ?
                          <>
                          <h4 className={'pMargin'}>Achievements: </h4>
                          <div>
                            {this.state.outputData.achievement.achievements.map(function(data, index){
                               return (
                                   <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                     <tbody>
                                         {data.achieveType ?
                                             <tr>
                                                 <th style={{width: "25%"}}>Achievement Type</th>
                                                 <td>{data.achieveType}</td>
                                             </tr>
                                             : null
                                         }
                                         {data.achievements ?
                                             <tr>
                                                 <th style={{width: "25%"}}>Achievements</th>
                                                 <td>{data.achievements}</td>
                                             </tr>
                                             : null
                                         }
                                         {data.duration ?
                                             <tr>
                                                 <th style={{width: "25%"}}>Duration</th>
                                                 <td>{data.duration}</td>
                                             </tr>
                                             : null
                                         }
                                     </tbody>
                                  </table>
                               )
                            })}
                          </div>
                          </>
                          : null
                      }

                      {this.state.outputData.extraCurricular.extracurricular && this.state.lengthExtraCurricular ?
                          <>
                          <h4 className={'pMargin'}>Extra Curricular Activities: </h4>
                          <div>
                            {this.state.outputData.extraCurricular.extracurricular.map(function(data, index){
                                return (
                                    <table className={'table table-bordered table-striped table-hover tableBordered'} key={index}>
                                        <tbody>
                                            {data.type ?
                                                <tr>
                                                    <th style={{width: "25%"}}>Type</th>
                                                    <td>{data.type}</td>
                                                </tr>
                                                : null
                                            }
                                            {data.desc ?
                                                <tr>
                                                    <th style={{width: "25%"}}>Description</th>
                                                    <td>{data.desc}</td>
                                                </tr>
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                )
                            })}
                          </div>
                          </>
                          : null
                      }

                      {this.state.outputData.summary.length ?
                          <>
                          <h4 className={'pMargin'}>Summary: </h4>
                          <div className={'tableBordered'}>
                            {this.state.outputData.summary.split('\n').map(function(data, index) {
                              return <p className={'brMargin'} key={index}>{data}</p>
                            })}
                          </div>
                          </>
                          : null
                      }

                      {/*<pre>*/}
                      {/*	{JSON.stringify(this.state.outputData, null, 2)}*/}
                      {/*</pre>*/}
                    </div>
                  </div>
                  : null
              }

              <div id="alertModal" className="modal" role="dialog" style={styles}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close"
                        data-dismiss="modal" onClick={this.handleClose}>&times;</button>
                      <h4 className="modal-title">Alert Messgae</h4>
                    </div>
                    <div className="modal-body">
                        <p> {this.state.alertMessage} </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default"
                        data-dismiss="modal" onClick={this.handleClose}>Close
                      </button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className={'col-sm-1'}>
            </div>
          </div>
          <footer className="page-footer font-small blue footer">
            <div className="footer-copyright text-center py-3">© 2020 Copyright:
              <h4> springrole.com</h4>
            </div>
          </footer>
        </div>

      )
    }
}

class App extends Component {

    render() {
        return (
            <div className="App">
              <InputForm />
            </div>
        )
    }
}

export default App;
